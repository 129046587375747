<template lang="">
  <div>
    <datatable
      class="mt-4"
      :data-of-table="getList"
      :table-columns="fields"
      :total="getTotal"
      :resource="`scientists`"
      :is-busy="getLoading"
      :buttons="[]"
      @edit="edit"
      @remove="remove"
    />
  </div>
</template>
<script>
import {
  required, email,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import datatable from '@/@core/components/datatable/datatable.vue'

export default {
  components: {
    datatable,
    // BRow,
    // BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {
          key: 'word', label: 'Rang mondial', sortable: true,
        },
        {
          key: 'nom', label: 'Nom & Prénom du scientifique',
        },
        {
          key: 'pays.libelle', label: 'Pays d\'origine',
        },
        {
          key: 'institution', label: 'Rang université',
        },
        {
          key: 'universite', label: 'Université', sortable: true,
        },
        {
          key: 'domaine', label: 'Domaine', sortable: true,
        },
        {
          key: 'totalHindex', label: 'T. H index',
        },
        {
          key: 'totalIndex', label: 'T. i10 Index',
        },
        {
          key: 'totalCitation', label: 'Total Citation',
        },
        'actions',
      ],
      isAddNewUserSidebarActive: false,
      scientist: {
        word: 0,
        nom: '',
        universite: '',
        domaine: '',
        totalHindex: 0,
        totalIndex: 0,
        totalCitation: 0,
        institution: 0,
        pays: '',
        etat: '',
      },
      items: [],
      required,
      email,
      loading: false,
      update: false,
      header: 'Ajouter un utilisateur',
      id: '',
    }
  },
  computed: {
    getList() {
      return this.$store.getters['scientists/list']
    },
    getTotal() {
      return this.$store.getters['scientists/totalItems']
    },
    getLoading() {
      return this.$store.getters['scientists/loading']
    },
  },
  mounted() {
    if (this.$store.getters['scientists/list'].length === 0) {
      this.$store.dispatch('scientists/list', { params: null })
    }
  },
  methods: {
    valid() {
      this.$bvModal
        .msgBoxConfirm('Êtes-vous sur ?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          this.boxOne = value
        })
    },
    reject() {
      this.$bvModal
        .msgBoxConfirm('S\'il vous plait veuillez entrez un commenteur avant de rejeter ', {
          title: 'Rejet',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Rejeter',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
    resetForm() {
      this.scientist = {
        word: 0,
        nom: '',
        universite: '',
        domaine: '',
        totalHindex: 0,
        totalIndex: 0,
        totalCitation: 0,
        institution: 0,
        pays: '',
        etat: '',
      }
    },
    hide() {
      this.scientist = {
        word: 0,
        nom: '',
        universite: '',
        domaine: '',
        totalHindex: 0,
        totalIndex: 0,
        totalCitation: 0,
        institution: 0,
        pays: '',
        etat: '',
      }
      this.isAddNewUserSidebarActive = false
    },
    filter(params) {
      const body = params
      this.$store.dispatch('scientists/list', { params: body })
    },
    edit(items) {
      this.user = {
        word: items.word,
        nom: items.nom,
        universite: items.universite,
        domaine: items.domaine,
        totalHindex: items.totalHindex,
        totalIndex: items.totalIndex,
        totalCitation: items.totalCitation,
        institution: items.institution,
        pays: items.pays['@id'],
        etat: items.etat['@id'],
      }
      this.id = items.id
      this.header = 'Update un utilisateur'
      this.isAddNewUserSidebarActive = true
    },
    async remove(items) {
      this.id = items.id
      this.modalShow = true
    },
    async deleteData() {
      try {
        // const { token } = JSON.parse(localStorage.getItem('user'))
        await this.$store.dispatch('scientists/delete', { id: this.id, params: null })
        // await this.$http.delete(`sites/${this.id}`, { headers: { Accept: 'application/json', Authorization: `Bearer ${token}` } })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression effectuée',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.modalShow = false
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          this.loading = true
          if (this.update === true) {
            await this.$store.dispatch('scientists/update', { id: this.id, params: null, item: this.user })
          } else {
            await this.$store.dispatch('scientists/add', { page: 1, item: this.user, params: null })
            // eslint-disable-next-line no-return-assign
              .then(() => this.loading = false)
            // eslint-disable-next-line no-return-assign
              .catch(() => this.loading = false)
          }
          this.loading = false
          this.scientist = {
            word: 0,
            nom: '',
            universite: '',
            domaine: '',
            totalHindex: 0,
            totalIndex: 0,
            totalCitation: 0,
            institution: 0,
            pays: '',
            etat: '',
          }
          this.id = ''
          this.isAddNewUserSidebarActive = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Operation effectuée',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="">

</style>
